import React, { useState, useEffect } from 'react';
import toolsData from '../data/tools';
import Layout from '../components/layout/Layout';
import { Stack } from '@mui/material';
import { Link } from 'gatsby';

const categories = Object.keys(toolsData[0]);

const AiToolsPage = () => {
    const [activeCategory, setActiveCategory] = useState(categories[0]); // Default category
    const [activeSubCategory, setActiveSubCategory] = useState(Object.keys(toolsData[0][categories[0]])[0]); // Default subcategory
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

    // Handle category click
    const handleCategoryClick = (category) => {
        setActiveCategory(category);
        setActiveSubCategory(Object.keys(toolsData[0][category])[0]); // Set first subcategory of the new category
    };

    // Handle subcategory click
    const handleSubCategoryClick = (subCategory) => {
        setActiveSubCategory(subCategory);
    };

    // Initialize HubSpot form when the modal is open
    useEffect(() => {
        if (isModalOpen) {
            initializeHubspotForm();
        }
    }, [isModalOpen]);
    useEffect(() => {
        const loadHubspotFormScript = () => {
            if (!document.querySelector('#hubspot-form-script')) {
                const script = document.createElement('script');
                script.src = 'https://js.hsforms.net/forms/v2.js';
                script.id = 'hubspot-form-script';
                script.async = true;
                document.body.appendChild(script);
            }
        };
        loadHubspotFormScript(); // Load HubSpot script as soon as the component mounts
    }, []);

    // Function to initialize HubSpot form
    const initializeHubspotForm = () => {
        if ('hbspt' in window) {
            window.hbspt.forms.create({
                portalId: '20309806',
                region: 'na1',
                formId: '5bfd574f-4212-4b28-b827-7a8e0312823d',
                target: '#form-container',
            });
        } else {
            setTimeout(initializeHubspotForm, 500);
        }
    };

    return (
        <Layout>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4} alignItems="center" className="sm:px-[60px]">
                <section className="relative">
                    <div className="container mx-auto px-8 py-10 sm:py-20">
                        <span className="text-sm text-gray-500 mb-4 block">AI AND SOFTWARE TOOLS GALLERY</span>
                        <h1 className="text-4xl sm:text-6xl font-bold mb-6 leading-tight">
                            Top AI and Software Tools for your <br /> Innovation Journey
                        </h1>
                        <p className="text-lg text-gray-600 mb-8 leading-relaxed">
                            Discover Cutting-Edge Tools and Technology to Transform and Enhance Your Workflow
                            Effectively and Efficiently
                        </p>
                        <button
                            className="text-white py-2 px-4 md:py-3 md:px-6 text-sm md:text-base rounded-lg bg-indigo-600 hover:bg-indigo-700transition-colors"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Hire AI Engineers
                        </button>

                    </div>
                </section>

                <Stack alignItems="center" justifyContent="center">
                    <img
                        src="https://kovil.ai/wp-content/uploads/2024/05/AItoolsHero.png"
                        className="max-w-full h-auto object-cover"
                        alt="AI Tools"
                        loading="lazy"
                    />

                </Stack>
            </Stack>

            <div className="bg-[#f7f9fc] p-8 sm:p-12 font-sans">
                {/* Top Category Tabs */}
                <div className="flex flex-col sm:flex-row justify-center sm:space-x-8 mb-6 bg-white p-2 rounded-lg">
                    {categories.map((category) => (
                        <button
                            key={category}
                            className={`px-6 py-2 text-lg font-medium rounded-md focus:outline-none transition-colors duration-300 ${activeCategory === category ? 'bg-black text-white' : 'bg-transparent text-black hover:bg-gray-100'
                                }`}
                            onClick={() => handleCategoryClick(category)}
                        >
                            {category.toUpperCase()}
                        </button>
                    ))}
                </div>

                <div className="flex flex-col sm:flex-row">
                    {/* Subcategories Sidebar */}
                    <div className="sm:w-1/4 bg-transparent p-4 border-r border-gray-300">
                        <h3 className="text-lg font-bold mb-4">Category</h3>
                        {Object.keys(toolsData[0][activeCategory]).map((subCategory) => (
                            <div
                                key={subCategory}
                                className={`p-2 mb-2 cursor-pointer rounded transition-colors duration-300 ${activeSubCategory === subCategory ? 'font-bold text-blue-600' : 'text-black hover:bg-gray-100'
                                    }`}
                                onClick={() => handleSubCategoryClick(subCategory)}
                            >
                                {subCategory.replace(/_/g, ' ').toUpperCase()}
                            </div>
                        ))}
                    </div>

                    {/* Products Section */}
                    <div className="sm:w-3/4 p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 h-fit">
                        {toolsData[0][activeCategory][activeSubCategory].map((product) => (
                            <div key={product.id} className="bg-white border rounded-lg shadow-md p-6">
                                {/* Flex container for logo and name */}
                                <div className="flex items-center mb-4">
                                    <img src={product.logo} alt={product.name} className="h-12 mr-4" loading="lazy"/>
                                    <h3 className="font-bold text-xl">{product.name}</h3>
                                </div>
                                <div className="text-sm font-medium bg-blue-100 text-blue-700 rounded-full px-2 py-1 inline-block mb-2">
                                    {product.category}
                                </div>
                                <p className="text-gray-700 mb-4">{product.description}</p>
                                <Link
                                    to={`/tool/${product.slug}`} // Use the slug to navigate
                                    state={{ tool: product }} // Pass the tool data if needed
                                    className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-lg  transition-colors"
                                >
                                    Preview
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Modal for Contact Form */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="relative bg-white sm:p-8 rounded-lg md:w-1/2 h-[98%] sm:h-[92%]">
                        <button
                            className="absolute top-2 right-2 text-gray-600 hover:text-black text-3xl z-10"
                            onClick={() => setIsModalOpen(false)}
                        >
                            &times;
                        </button>
                        <div className="relative bg-gray-200" id="get-started">
                            <div className="relative mx-auto px-4 py-4 sm:py-12 sm:px-6 lg:px-8 lg:py-4">
                                <div className="h-[80%]">
                                    <p className="sm:mt-2 text-indigo-700 text-3xl font-extrabold tracking-tight sm:text-4xl">
                                        Let's Meet
                                    </p>
                                    <div className="mt-2 sm:mt-4">
                                        <div id="form-container"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default AiToolsPage;
